import * as React from "react"
import { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndividualEpoch = ({ data, listings }) => {
    let epochNum = data.node.name.split(' #')[1];
    let liveUrl = '/epoch-' + epochNum;
    let mobilePicUrl =  "https://d28a5q050a9bu1.cloudfront.net/images/small-site-epochs/smaller-website-sizes/" + epochNum + ".png";
    let listingInfo = null;

    if (data.node.name in listings) {
      listingInfo = listings[data.node.name];
    }
    return (
        <div className='col30 left collection-item'>
            <Link className='image' to={liveUrl} style={{ backgroundImage: `url(${mobilePicUrl})`}} alt={`Epoch #${epochNum}`}></Link>
            <div className='details'>
                <h4>{data.node.name}</h4>
                <Link className='button black detailed-view' to={liveUrl}>View</Link>
                {!!listingInfo &&
                <Link className='listing-link' to={listingInfo.marketURL}>Listed on {listingInfo.market} for {listingInfo.price} SOL</Link>
                }
            </div>
        </div>
    )
}

const PaginatedEpochs = ({ data, listings, RenderComponent, pageLimit, dataLimit }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [clusterFilterParam, setClusterFilterParam] = useState("All");
    const [minFilterParam, setMinFilterParam] = useState("All");
    const [maxFilterParam, setMaxFilterParam] = useState("All");
    const [saleFilterParam, setSaleFilterParam] = useState("All");
    const [neighborhoodFilterParam, setNeighborhoodFilterParam] = useState("All");
    const [nodeFilterParam, setNodeFilterParam] = useState("All");
    const [rotationFilterParam, setRotationFilterParam] = useState("All");
    const [showFilters, setShowFilters] = React.useState(false);

    let epochs = data;
    let [pages] = useState(Math.round(epochs.length / dataLimit));
    const [searchQ, setSearchQ] = useState("");

    function applyFilters(allEpochs) {
      let filteredEpochs = allEpochs.filter((epoch) => {
          if (epoch.node.name.toLowerCase().indexOf(searchQ) > -1) {
            return epoch;
          }
        });

      filteredEpochs = filteredEpochs.filter((epoch) => {
        if (epoch.node.cluster_type == clusterFilterParam) {
          return epoch;
        } else if (clusterFilterParam == "All") {
          return epoch;
        }
      });
      filteredEpochs = filteredEpochs.filter((epoch) => {
        let min_depth = epoch.node.turbine_depth_minimum;
        if (min_depth == minFilterParam) {
          return epoch;
        } else if (minFilterParam == "All") {
          return epoch;
        }
      });

      filteredEpochs = filteredEpochs.filter((epoch) => {
        let max_depth = epoch.node.turbine_depth_maximum;
        if (max_depth == maxFilterParam) {
          return epoch;
        } else if (maxFilterParam == "All") {
          return epoch;
        }
      });

      filteredEpochs = filteredEpochs.filter((epoch) => {
        let neigh = epoch.node.neighborhood_assignment;
        if (neigh == neighborhoodFilterParam) {
          return epoch;
        } else if (neighborhoodFilterParam == "All") {
          return epoch;
        }
      });

      filteredEpochs = filteredEpochs.filter((epoch) => {
        let node_style = epoch.node.node_styles;
        if (node_style == nodeFilterParam) {
          return epoch;
        } else if (nodeFilterParam == "All") {
          return epoch;
        }
      });

      filteredEpochs = filteredEpochs.filter((epoch) => {
        let rotation = epoch.node.scheduled_rotation;
        if (rotation == rotationFilterParam) {
          return epoch;
        } else if (rotationFilterParam == "All") {
          return epoch;
        }
      });
      
     // listing status
     filteredEpochs = filteredEpochs.filter((epoch) => {
      if (saleFilterParam == "All") {
        return epoch;
      } else if (saleFilterParam === 'Listed-High' || saleFilterParam === 'Listed-Low') {
        if (epoch.node.name in listings) {
          return epoch;
        }
      } else if (saleFilterParam === 'Unlisted') {
        if (listings[epoch.node.name] == undefined) {
          return epoch;
        }
      }
    });
      if (saleFilterParam === 'Listed-High') {
        filteredEpochs = filteredEpochs.sort((a, b) => (listings[b.node.name].price) > (listings[a.node.name].price) ? 1 : -1);
      } else if (saleFilterParam === 'Listed-Low') {
        filteredEpochs = filteredEpochs.sort((a, b) => (listings[a.node.name].price) > (listings[b.node.name].price) ? 1 : -1);
      } else {
        filteredEpochs = filteredEpochs.sort((a, b) => (parseInt(a.node.name.substring(6))) > parseInt(b.node.name.substring(6)) ? 1 : -1);
      }
      
      epochs = filteredEpochs;
      pages = Math.ceil(epochs.length / dataLimit);
     }

    function applyClusterFilter(cluster) {
        setClusterFilterParam(cluster);
      
        setCurrentPage(1);
     }

    function applyMinFilter(val) {
        setMinFilterParam(val);
        setCurrentPage(1);
    }

    function applyMaxFilter(val) {
        setMaxFilterParam(val);
        setCurrentPage(1);
    }

    function applySaleFilter(val) {
      setSaleFilterParam(val);
      setCurrentPage(1);
    }
    
    function applyNeighborhoodFilter(val) {
        setNeighborhoodFilterParam(val);
        setCurrentPage(1);
    }

    function applyNodeFilter(val) {
      setNodeFilterParam(val);
      setCurrentPage(1);
    }

    function applyRotationFilter(val) {
      setRotationFilterParam(val);
      setCurrentPage(1);
    }

     function updateSearchQ(query) {
        setSearchQ(query);
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
     }
   
     function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
        const filterSection = document.getElementById('filters');
        filterSection.scrollIntoView({ behavior: 'smooth' });

     }
   
     const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        applyFilters(data);
        
        return epochs.sort((a, b) => (parseInt(a.node.name.substring(7))) > parseInt(b.node.name.substring(7)) ? 1 : -1).slice(startIndex, endIndex);
     };
   
     const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pages).fill().map((_, idx) => start + idx + 1);
     };

    return (
        <>
            <div className='filters' id='filters'>
                <h2 onClick={() => {setShowFilters(!showFilters);}}>Filter Collection
                    <span>
                      {showFilters ? (
                          <span className='arrow-down'></span>
                      ) : (
                          <span className='arrow-up'></span>
                      )}
                    </span>
                </h2>
                {showFilters && (
                    <div className='filters-list clearfix'>
                      <select className='left col20'
                        onChange={(e) => {
                          applyClusterFilter(e.target.value);
                        }}
                        aria-label="Filter Epochs By Cluster Type"
                      >
                        <option value="All">Cluster Type</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <select className='left col20'
                        onChange={(e) => {
                          applyMinFilter(e.target.value);
                        }}
                        aria-label="Filter Epochs By Min. Depth"
                      >
                        <option value="All">Min. Depth</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                      <select className='left col20'
                        onChange={(e) => {
                          applyMaxFilter(e.target.value);
                        }}
                        aria-label="Filter Epochs By Max Depth"
                      >
                        <option value="All">Max Depth</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </select>
                      <select className='left col20'
                        onChange={(e) => {
                          applyNeighborhoodFilter(e.target.value);
                        }}
                        aria-label="Filter Epochs By Neighborhood"
                      >
                        <option value="All">Neighborhood</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <select className='left col20'
                        onChange={(e) => {
                          applyNodeFilter(e.target.value);
                        }}
                        aria-label="Filter Epochs By Node Style"
                      >
                        <option value="All">Node Style</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                      </select>

                      <select className='left col20'
                        onChange={(e) => {
                          applyRotationFilter(e.target.value);
                        }}
                        aria-label="Filter Epochs By Rotation Schedule"
                      >
                        <option value="All">Rotation Schedule</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>

                      <select className='left col20'
                        onChange={(e) => {
                          applySaleFilter(e.target.value);
                        }}
                        aria-label="Filter Epochs By Listing Status"
                      >
                        <option value="All">Listing Status</option>
                        <option value="Listed-High">Currently listed (High --> Low)</option>
                        <option value="Listed-Low">Currently listed (Low --> High)</option>
                        <option value="Unlisted">Not currently for sale</option>
                      </select>


                          <input className='left col20'
                              type="search"
                              name="search-form"
                              placeholder="Search by number"
                              value={searchQ}
                              /*
                              // set the value of our useState searchQ
                              //  anytime the user types in the search box
                              */
                              onChange={(e) => updateSearchQ(e.target.value)}
                          />

                    </div>
                )}
            </div>

            <div>

                <div className='collections-list'>
                    {getPaginatedData().map((d, idx) => (
                        <RenderComponent key={idx} data={d} listings={listings} />
                    ))}
                </div>

                <div className='pagination'>

                    {/* show page numbers */}
                    {getPaginationGroup().map((item, index) => (
                        <div className={`button ${currentPage === item ? "black" : ""}`}
                        active={currentPage === item}
                        key={index}
                        onClick={changePage}
                        pageNum={item}
                        >
                        <span>{item}</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

const EpochGallery = ({ data }) => {
  const epochs = data.allEpochsJson.edges.sort((a, b) => (parseInt(a.node.name.substring(7))) > parseInt(b.node.name.substring(7)) ? 1 : -1);

  const magicEdenURL = 'https://api-mainnet.magiceden.io/rpc/getListedNFTsByQuery?q=%7B%22%24match%22%3A%7B%22collectionSymbol%22%3A%22playground_epoch%22%7D%2C%22%24sort%22%3A%7B%22takerAmount%22%3A1%2C%22createdAt%22%3A-1%7D%2C%22%24skip%22%3A0%2C%22%24limit%22%3A500%7D';

  const [listingsData, setListingsData] = useState({});

  useEffect(() => {
    getListingsDataWithFetch();
  }, []);

  const getListingsDataWithFetch = async () => {
    // We want to make the Epochs name ("Epochs #235") the key
    let dictionaryListings = {};

    const magicResponse = await fetch(magicEdenURL);
    const magicJson = await magicResponse.json();

    (magicJson.results || []).forEach(epoch => {
      dictionaryListings[epoch.title] = {
        market: 'Magic Eden',
        marketURL: 'https://magiceden.io/marketplace?collection_symbol=playground_epoch',
        price: epoch.price
      };
    });
    
    setListingsData(dictionaryListings);
  };

  return (
    <Layout>
        <Seo title="Playground - Epochs Gallery View" />

        <section className='section-title white collection'>
            <div className='container clearfix'>
                <div className='col50 left info'>
                    <div className='clearfix tags'>
                        <Link to='/collections/' className='button black left'>Collection</Link>
                        <a className='button black left'>1000 minted</a>
                    </div>
                    <h1>Epochs <span>by Eko33</span></h1>
                    <p className='big'>Inspired by the passage of time, Epochs (created by Eko33) is a collection of 1,000 unique randomly generated pieces of art stored on the Solana blockchain.</p>
                </div>
                <div className='col50 left image'>
                    <img src="https://d28a5q050a9bu1.cloudfront.net/images/real-epochs/real-epoch-images/71.png" />
                </div>
            </div>
        </section>

        <section className='collection-detail'>
            <div className='container clearfix'>
                <PaginatedEpochs
                    data={epochs}
                    listings={listingsData}
                    RenderComponent={IndividualEpoch}
                    pageLimit={11}
                    dataLimit={99}
                />
            </div>
        </section>

    </Layout>
  )
}

export const query = graphql`
    query EpochGalleryQuery {
        allEpochsJson {
            edges {
              node {
                id
                name
                neighborhood_assignment
                cluster_type
                colors
                node_styles
                scheduled_rotation
                seed
                shadow_blur_level
                shape
                show_tiles_at_depth
                turbine_depth_maximum
                turbine_depth_minimum
              }
            }
        }
    }
`

export default EpochGallery
